@import '../../styles/colors';

.modalBody {
	margin: 50px;
}

.modalFooter {
	display: flex;
	justify-content: flex-end;

	& button:not(:last-of-type) {
			margin-right: 8px;
	}
}

.highlight {
	color: $gy-dark-blue-color;
}