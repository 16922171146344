@import '../../../../styles/colors.scss';

.modalContainer {
  width: 90%;
  padding: 40px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;

  &>button {
    position: absolute;
    right: 12px;
    top: 12px
  }

  &>div {
    display: flex;
    align-items: baseline;

    &>*:first-child {
      margin-right: 16px;
    }

    &>*:not(:first-child) {
      margin-right: 4px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  &>*:not(:first-of-type) {
    margin-left: 8px;
  }
}

.row {
  display: flex;
}

.boldText {
  font-weight: bold;
}

.statusRow {
  align-items: center;
}

.excludeDayWrapper {
  align-items: flex-end;
  justify-content: flex-end;

  &>.addExclutionBtn {
    margin: 0 4px 5px;
  }
}

.monthModification {
  justify-content: space-between;
  align-items: center;
}

.round {
  position: relative;
  float: right;
  margin-left: 8px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: -10px;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.statusText {
  border-radius: 16px;
  padding: 8px 12px;
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 20px;

  &>img {
    margin-left: 12px;
  }
}

.frequencyContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;

  &>*:not(:first-of-type) {
    margin-left: 20px;
  }
}

.addExclutionContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;

  .addExclutionBtn {
    margin: 23px;
  }
}

.doneStatus {
  @extend .statusText;
  background-color: #BECDED;
  color: #0041A0;
}

.runningStatus {
  @extend .statusText;
  background-color: #DBFFD2;
  color: #2A7200;
}

.errorStatus {
  @extend .statusText;
  background-color: #dc143c;
  color: #F43B3B;
}

.abortedStatus {
  @extend .statusText;
  background-color: #C6C6C6;
  color: #444444;
}

.queuedStatus {
  @extend .statusText;
  background-color: #FFF6D2;
  color: #7A6D34;
}

.timeDropdown {
  min-width: 56px;
  margin-right: 4px;
}

.exceptionContainer {
  //margin-top: 20px;

  .addExclutionContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .addExclutionBtn {
    margin-top: 24px;
    margin-left: 30px;
  }
}

.notificationContainer {
  .notificationTemplateContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;  

    &>*:not(:first-of-type) {
      margin-left: 20px;
    }  
  }
}


.monthsContainer {
  margin-top: 20px;
}

.wrapContainer {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.monthsCheckContainer {
  @extend .wrapContainer;

  &>* {
    width: 25%;
    margin-top: 20px;
  }

}

.daysOfWeekWrapper {
  @extend .wrapContainer;
  justify-content: flex-start;

  &>* {
    width: 25%;
    margin-top: 20px;
    margin-left: 20px
  }
}

.especificDaysWraper {
  @extend .wrapContainer;

  &>* {
    width: 50%;
    margin-top: 20px;
  }
}

.dayOfMonth {
  display: flex;
  flex-direction: column;
}

.dayInput {
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
}

.dayOfWeek {
  display: flex;
  flex-direction: column;

  input {
    margin-right: 20px;
  }
}

.weekDayWrapper {
  @extend .wrapContainer;

  &>* {
    width: 40%;
    margin-top: 10px;
  }

  &>*:not(:first-of-type) {
    margin-left: 20px;
  }
}

.monthlyPeriodicity {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.generalContainer {
  @extend .wrapContainer;

  &>* {
    width: 49%;
    // margin-left: 10px;
    // margin-right: 10px;
  }

  // &>*:not(:first-of-type) {
  //   margin-left: 20px;
  // }
}