.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  &>*:not(:first-of-type) {
    margin-left: 30px;
  }

}

.iconBtn i {
  font-size: 30px;
}