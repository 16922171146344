@import './styles/colors.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pivot-container>div:last-child{
  border-top: 1px solid #114375;
  margin-top: 16px;
  padding-top: 16px;
}

.vertical-pivot {
  display: flex;
}

.vertical-pivot>div:last-child {
  width: 90%;
}

.vertical-pivot>.ms-Pivot {
  border-right: 2px solid $gy-blue-color;

  .ms-Pivot-link {
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 300;
    background-color: rgba(17, 67, 117,.08) !important;
  }
  .ms-Pivot-link.is-selected {
    border-left: 4px solid $gy-blue-color;
    color:white;
    background-color: rgba(17, 67, 117,.6) !important;
    font-weight: 600;
  }
  .ms-Pivot-link.is-selected:hover {
    border-left: 4px solid $gy-blue-color;
    color:white;
    background-color: rgba(17, 67, 117,.8) !important;
    font-weight: 600;
  }
  .ms-Pivot-link:hover {
    background-color: rgba(17, 67, 117,.2) !important;
  }
  .linkIsSelected-192::before {
    background-color: transparent;
  }
}

.vertical-pivot-container {
  padding-left: 8px;
}

.ms-DetailsHeader {
  background: $gy-dark-blue-color;
  padding: 0px;

  .ms-DetailsHeader-cellName {
    color: $gy-white-color;
  }
}

.ms-DetailsHeader-cell:hover {
  .ms-DetailsHeader-cellName {
    color: $gy-blue-color;
  }
}

.stepper {
  & > ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: $gy-blue-color;

    & > li {
      padding: 10px 10px;
      margin-right: 10px;
      background-color: #e5edf6 !important;
    }

    & > li.active {
      font-weight: 600;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.stackStyles {
  position: relative;
  top: 45px;
  transition: 0.5s;
}

.switchStyles {
  position: relative;
  background-color: $gy-white-color;
  transition: 0.5s;
  padding-top: 30px;
}

.openMenu {
  left: 15%;
  width: calc(100% - 15%);
}

.closeMenu {
  left: 4%;
  width: calc(100% - 4%);
}

/** Shared styles  */

.marginTop20 {
  margin-top: 20px;
}

// forms
.inputGroup {
  display: flex;

  &>div:not(:last-of-type) {
    margin-right: 8px;
  }
}

.inputWithButton {
  align-items: flex-end;

  &>div:first-of-type {
    width: -webkit-fill-available;
  }
}

.requiredField div label {
  color: $gy-dark-blue-color !important;
}

.requiredField div label::after {
  color: red !important;
  content: " *" !important;
  padding-right: 12px !important;
}

// modal

.modalHeader {
  display: flex;
  justify-content: center;

  &>button {
    position: absolute;
    right: 12px;
    top: 12px
  }
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  &>div {
    display: flex;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}

.modalContainer {
  min-width: 800px;
  height: 80% !important;
  width: 80%;
}

.modalBody {
  padding: 12px;
}

.marginTop18 {
  margin-top: 18px;
}


.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;

  &>*:not(:first-of-type) {
    margin-left: 8px;
  }
}

.tableResized th {
  position: relative;
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

.resizer:hover,
.resizing {
  border-right: 2px solid blue;
}

.resizable {
  border: 1px solid gray;
  height: 100px;
  width: 100px;
  position: relative;
}

.mainContainer {
  padding: 20px !important;
}

.titleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.iconContainer img {
  margin-top: 3px;
  padding: 5px;
  width: 30px;
  padding-right: 15px;
}

.padding30 {
  padding-left: 30px;
  padding-right: 30px;
}

#reports-table {
    
    #reportName {
        width: 35% !important;
    }

    #customer {
        width: 20% !important;
    }
}