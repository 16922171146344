@import '../../../../../styles/colors';

.container > div {
    padding-top: 10px;
}

.infoBlocks {
    display: flex;
    align-items: left;
}


.infoBlockData {
    border: 3px solid $gy-blue-color;
    padding: 15px;
    margin-right: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.row span:first-child {
    padding-right: 10px;
}

.row span:last-child {
    font-weight: 500;
}

.highlight {
    color: $gy-dark-blue-color;
}