@import '../../../../../../../styles/colors.scss';

.detailsWrapper {
  display: block;
}

.detailsBackground {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 99;
  overflow-y: auto;
}

.detailsContainer {
  width: 90%;
  margin: 45px auto;
}

.detailsContainer {
  width: 90%;
  margin: 45px auto;
}

.detailsHeader {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-color: $gy-blue-color;
}

.detailsHeader span {
  color: #ffffff;
}

.detailsBody {
  background-color: #ffffff;
  padding: 10px 25px 50px 25px;
}

.addBlock {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding-right: 15px;

  .addingFields {
    align-items: baseline;

    .addingField {
        margin-right: 20px;
    }

    .fromToFields {
        display: flex;
        flex-direction: row;
    }
}

  .addButton {
    margin-top: 10px;
    width: inherit;
  }
}

.tireDetailsBlock {
  padding-right: 30px;
  width: max-content;
}

.tirePostedSpareStockBlock {
  text-align: center;
  color: $gy-dark-blue-color;
  margin: 45px auto;
  padding-left: 75px;
}

.tableHeading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 0;

  &>div {
    display: flex;
    align-items: center;
  }
}

.refreshButton {
  margin-left: 5px;
}

.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;

  &>* {
    margin-right: 16px;
  }
}

.trBasic {
  &:hover {
    background-color: #eee;
  }
}

.trSelected td{
  background-color: rgba($gy-blue-color, 0.3);
}

.round {
  position: relative;
  float: right;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: -10px;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.table-wrapper {
  max-height: 400px;
  padding-right: 10px;
  overflow: scroll;
}

.table-wrapper::-webkit-scrollbar {
  background-color: rgba(17, 67, 117, 0.15);
  padding-right: 10px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: $gy-dark-blue-color;
  border-radius: 20px;
}

.table-wrapper::-webkit-scrollbar-corner {
  display: none !important;
}

.highlight {
  color: $gy-dark-blue-color;
}

.statusContainer {
  display: flex;
  justify-content: space-between;
}

.statusRunning {
  font-size: 20px;
  color: rgba(165,41,46,255);
}

.statusComplete {
  font-size: 20px;
  color: green;
}

.statusDefault {
  font-size: 20px;
  color: black;
}