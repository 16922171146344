.submitContainer {	
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-top: 20px;
}

.boxContainer {
	& > *:not(:first-of-type) {
			margin-top: 80px;
	}
}