@import '../../../../styles/colors';

.searchPanelWrapper {
    display: inline-block;
    padding: 10px 0;
}

.searchPanel {
    display: flex;
}

.searchPanel > div {
    align-self: flex-end;
    padding-right: 15px;
}

.searchPanel > div > button {
    border: 0;
    color: #ffffff;
    background-color: $gy-blue-color;
}

.tableHeading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading>div {
    display: flex;
    align-items: center;
}

.modalWrapper {
    min-width: 1200px;
}

.modalContainer {
    padding: 12px;
}

.modalHeader {
    display: flex;
    justify-content: center;

    &>button {
        position: absolute;
        right: 12px;
        top: 12px
    }
}

.modalBody {
    display: flex;

    &>div {
        flex: 1;

        &>div {
            padding-bottom: 15px;
        }
    }
}

.modalFotter {
    display: flex;
    justify-content: flex-end;
}

.postTypeOptions>div {
    display: flex;

    &>div {
        align-self: center;

        &>div>div {
            display: flex;

            &>div {
                margin: 0;
                padding-right: 10px;
            }
        }
    }

    &>button {
        align-self: flex-end;
    }
}

.totalInfo>div {
    display: flex;

    &>div {
        padding-right: 10px;
    }

    &>button {
        align-self: flex-end;
    }
}

.billingPeriod>div {
    display: flex;

    &>div {
        padding-right: 10px;
    }
}

.location>div>div {
    display: flex;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    width: auto;

    &>div {
        display: flex;
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table thead>tr>th {
    cursor: pointer;
    padding: 0.5em;
    text-align: left;
    background-color: $gy-blue-color;
    color: white;
    width: auto;

    &:hover {
        background-color: rgba($gy-blue-color, 0.5);
    }

    &:first-of-type {
        width: max-content;
    }

    &:last-of-type {
        padding-right: 1em;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.table tbody>tr>td {
    padding: 0.5em;
    border-bottom: 1px solid #ccc;
    vertical-align: middle;
    width: auto;

    &:first-of-type {
        width: max-content;
    }

    &:last-of-type {
        padding-right: 1em;
    }
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.trError {
    background-color: rgba(red, 0.1);
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    &>button:not(:last-of-type) {
        margin-right: 16px;
    }

    &>div {
        margin-left: 16px;

        &>button:not(:last-of-type) {
            margin-right: 16px;
        }
    }
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
}

.table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
}

.table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
}

.highlight {
    color: $gy-dark-blue-color;
}

.vNo{
    width: 10px;
}

.datePickerIdle {
    width: 100px;
}

.datePickerInfoRecorded {
    width: 150px;
}

.modalContainer {
  min-width: 90%;
  height: 80% !important;
  width: 80%;
}

.ms-Dialog-main {
  overflow-y: none;
}

.content::-webkit-scrollbar {
  /* solo oculta su visualizacion */
  display: none;
}

.modalBody {
  margin: 50px;
}

.modalButtonsDiv {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  & button:not(first-of-type) {
    margin-right: 8px;
  }
}

.modalFooter {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop25 {
  margin-top: 25px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.registryButtons button {
  margin-top: 36px;
  margin-left: 20px;
}

.marginTop50 {
  margin-top: 50px;
}

.marginLeft20 {
  margin-left: 20px;
}

.amountContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.editBtnContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.editBtnContainer>*:not(:last-child) {
  margin-right: 10px;
}

input:disabled,
textarea:disabled {
  color: black;
}

.spinner {
  font-size: 50px;
  margin-top: 20%;
}

.checkBoxContainer div {
  padding: 10px;
}

.customerSwitch {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.applyContainer {
  margin-top: 6%;
  display: flex;
  justify-content: flex-end;
}

.consignedTiresBtnContainer {
  margin-top: 18px;

}

.consignedTiresBtnContainer button {
  font-size: 10px;
}