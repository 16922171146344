@import "../../../styles/colors";

.detailsWrapper {
  display: block;
}

.detailsBackground {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 99;
  overflow-y: auto;
}

.detailsContainer {
  width: 60%;
  margin: 45px auto;
}

.detailsHeader {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-color: $gy-blue-color;
}

.detailsHeader span {
  color: #ffffff;
}

.detailsBody {
    background-color: #ffffff;
    padding: 10px 25px 15px 25px;
}

.closedModal {
  display: none;
}

.searchPanel > div {
  align-self: flex-end;
  padding-right: 15px;
}

.searchPanel > div > button {
  border: 0;
  color: #ffffff;
  background-color: $gy-blue-color;
}

.highlight {
  color: $gy-dark-blue-color;
}
