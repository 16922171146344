@import '../../../../../../styles/colors.scss';

.viewListingTabContainer > div {
    padding-top: 10px;
}

.viewListingSettings {
    display: flex;
    justify-content: space-between;
}

.filterButton {
    border-radius: 16px;
}

.viewButton {
    height: 20px;
}

.columnsList {
    display: flex;
    padding: 4px 5px;
    border: 2px solid $gy-dark-blue-color;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    .itemSelected {
        cursor: pointer;
        margin: 0 5px;
        padding: 0px 10px;
        border: 2px solid $gy-dark-blue-color;
        background-color: $gy-dark-blue-color;
        color: $gy-white-color;
        border-radius: 15px;
        white-space: nowrap;

        &:hover {
            background-color: $gy-white-color;
            color: $gy-black-color;
        }
    }

    .itemNotSelected {
        cursor: pointer;
        margin: 0 5px;
        padding: 2px 15px;
        border: 2px solid $gy-blue-color;
        border-radius: 15px;
        white-space: nowrap;
        &:hover {
            background-color: $gy-dark-blue-color;
            color: $gy-white-color;
        }
    }
}

.viewListingContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 8px;
    }
}

.table-wrapper {
    max-height: 700px;
    min-height: 700px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
    color: $gy-dark-blue-color;
}

tr {
    height: 75px;
}