@import '../../../../styles/colors';

//Customer details

.customerDetailsContainer {
  display: flex;
  align-items: flex-start;
}

.customerDetailsContainer>*:not(:first-child) {
  margin-left: 15px;
  margin-right: 15px;
}

.smallAutocomplete {
  width: 150px;
}

;

.bigAutocomplete {
  width: 300px;
}

.marginTopAutocomplete {
  margin-top: 5px;
}

.marginTop25 {
  margin-top: 25px;
}

.marginTop30 {
  margin-top: 30px;
}

.mileageContainer {
  margin-top: 50px;
}

//Post Mileage Adjustment Details 

.postMileageDetailsContainer {
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
}

.postMileageDetailsContainer>*:not(:first-child) {
  margin-left: 15px;
  margin-right: 15px;
}

.postMileageTireBrandContainer {
  display: flex;
  align-items: center;
}

.postMileageTireBrandContainer>* {
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
}

.postMileageDBCRVerticalContainer>*:not(:first-child) {
  margin-top: 15px;
}

.postMileageDBCRHorizontalContainer {
  display: flex;
  align-items: center;
}

.postMileageDBCRHorizontalContainer>* {
  margin-left: 5px;
  margin-right: 5px;
}

//Filters
.filterEditingContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.editCommentContainer {
  display: flex;
  align-items: flex-end;
}

.editCommentContainer>*:not(:last-child) {
  margin-left: 5px;
  margin-right: 5px;
}

//Button row
.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.buttonsContainer>* {
  margin-left: 5px;
  margin-right: 5px;
}

.buttonsContainer>*:not(:first-child) {
  margin-right: 0px;
}

.highlight {
  color: $gy-dark-blue-color;
}

.title {
  color: $gy-dark-blue-color;
  font-size: xx-large;
  margin-bottom: 30px;
}