
.container {
    & > div {
        padding-top: 16px;
    }
}

.headingBlock {
    display: flex;
    justify-content: space-between;
    padding: 0px;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-left: 16px;
    }
}