@import '../../../../../../../../styles/colors';

.modalContainer {
  min-width: 90%;
  height: 90% !important;
  width: 80%;
}

.ms-Dialog-main {
  overflow-y: none;
}

.content::-webkit-scrollbar {
  display: none;
}

.modalBody {
  margin: 50px;
}

.modalButtonsDiv {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  & button:not(first-of-type) {
    margin-right: 8px;
  }
}

.modalFooter {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}

.marginTop5 {
  margin-top: 5px;
}

.marginTop30 {
  margin-top: 30px;
}

.spinner {
  font-size: 50px;
  margin-top: 20%;
}

.highlight {
  color: $gy-dark-blue-color;
  margin-bottom: 20px
}