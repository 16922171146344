@import '../../../../../../../../styles/colors';

.mainBlock {
    & > div:first-child {
        display: flex;
        border: 1px solid $gy-blue-color;
        margin-bottom: 16px;

        & > div {
            flex: 1;
            margin: 16px;
        }
    
        & > div:nth-of-type(2) {
            flex: 0;
        }
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    & > * {
        margin-left: 16px;
    }
}