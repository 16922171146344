@import '../../../../../../styles/colors';

.mainBlock {
    display: flex;
    border: 1px solid $gy-blue-color;

    & > div {
        flex: 1;
        margin: 16px;
    }

    & > div:nth-of-type(2) {
        flex: 0;
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    & > * {
        margin-left: 16px;
    }
}