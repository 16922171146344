@import "../../../styles/colors";

.searchPanelWrapper {
    display: inline-block;
    padding: 10px 0;
}

.searchPanel {
    display: flex;
}

.searchPanel > div {
    align-self: flex-end;
    padding-right: 15px;
}

.searchPanel > div > button {
    border: 0;
    color: #ffffff;
    background-color: $gy-blue-color;
}

.detailsFooter {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
  }
  
  .detailsFooter > button {
    margin-left: 10px;
  }