@import '../../../../styles/colors';

.detailsRow {
    display: flex;
    flex-direction: row;
    margin-top: 15px;

    .rowWrapper {
        width: 100%;
    }

    .fieldsActions {
        float: right;
        margin-top: 10px;
    }

    button:not(:last-child) {
        margin-right: 15px;
    }

    .detailsColumn {
        width: 100%;
    }
}

.fields {
    display: flex;
    align-items: flex-end;

    .field {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: 5px;

        .searchButton {
            margin-left: 5px;
        }
    }

    .dateField {
        padding-bottom: 0 !important;
    }

    .mediumField {
        min-width: 200px;
    }

    .largeField {
        min-width: 300px;
    }

    .field:not(:last-child) {
        margin-right: 15px;
    }

}

.detailsSeparator {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
}

.customButton {
    outline: transparent;
    position: relative;
    font-family: Barlow Regular;
    -webkit-font-smoothing: antialiased;
    font-size: 14px !important;
    font-weight: 400;
    box-sizing: border-box;
    border: 1px solid rgb(0, 120, 212);
    display: inline-block;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 2px;
    min-width: 80px;
    height: 32px;
    background-color: rgb(0, 120, 212);
    color: rgb(255, 255, 255) !important;
    user-select: none;
    text-transform: none;
    letter-spacing: normal;
  
    & > span {
      display: flex;
      height: 100%;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
  
      & > span {
        display: block;
        flex-grow: 1;
        margin: 0px 4px;
        line-height: 100%;
        font-weight: 600;
      }
    }
  }

.customButton.disabled {
    border: 1px solid rgb(243, 242, 241);
    background-color: rgb(243, 242, 241);
    color: rgb(210, 208, 206) !important;
    cursor: unset;
}
  
.upload {
    display: none;
  }

.topInfo {
    float: right;
}

.highlight {
    color: $gy-dark-blue-color;
}