@import '../../../../../../../styles/colors.scss';

.modalFooter {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-left: 16px;
    }
}

.modalContainer {
    min-width: 960px;
    padding: 12px;
}

.row {
    display: flex;
}
