@import '~office-ui-fabric-core/dist/css/fabric.min.css';
@import '../../styles/colors';

.homeContainerClass {
    margin: 0px !important;
    padding: 0px;
}

.homeRowClass {
    display: flex;
    margin: 0 !important;
}

.homeSubSectionClass {
    height: calc((100vh - 200px) / 2);
    min-height: 240px;    
    margin: 10px;
    padding: 5px;
    background-color: $gy-white-color;
    border: 2px solid $gy-blue-color;
}

.fileSectionClass {
    height: calc(100vh - 175px);
    min-height: 480px;
    margin: 10px;
    padding: 5px;
    background-color: $gy-white-color;
    border: 2px solid $gy-blue-color;
}

.homePageImgClass {
    margin-top: 50px;
    margin-left: 65px;
    height: 550px;
    width: 1500px;
  }

