.miscData {
    padding: 0 15px 0 15px;

    .formContainer {
        display: flex;
        flex-direction: row;

        .mediumDropdown {
            min-width: 169px;
        }
    }
    
    .formContainer>*:not(:first-child) {
        margin-left: 10px;
    }

    .buttonContainer {
        display: flex;
        flex-direction: row-reverse;
      }
      
    .buttonContainer>*:not(:last-child) {
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .tableData {
        margin-top: 15px;
    }

    .actionButtons {
        margin-top: 15px;
        display: flex;
        justify-content: end;
    }
}
