@import '../../../../../../../../..//styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.viewButton {
    height: 20px;
}

.headingBlock {
    align-items: stretch;
    display: flex;

    & > div:first-of-type, & > div:last-of-type {
        display: flex;
        align-items: center;
    }
}

.gyBackground {
    background-color: $gy-blue-color;
}

.table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        cursor: pointer;
        padding: 0.5em 1.5em 0.5em 0.75em;
        text-align: left;
        width: 10%;
        background-color: $gy-dark-blue-color;
        color: white;
    
        &:hover {
            background-color: rgba($gy-dark-blue-color, 0.5);
        }
    }

    tbody td {
        padding: 0.5em 1.5em 0.5em 0.75em;
        border-bottom: 1px solid #ccc;
        vertical-align: middle;
    }
    
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
      color: $gy-dark-blue-color;
  }
