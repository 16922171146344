.availableTermsContainer {
  padding: 15px;

  .titleContainer {
    margin-top: 15px;
    text-align: center;
  }

  .topActionButtons {
    margin-bottom: 20px;
  }

  .bottomActionButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}

.changeStatusesContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & button {
    margin-right: 8px;
  }
}

.customListContainer {
  position: relative;
}

.customListOptionsContainer {
  position: absolute;
  z-index: 10000000;
  width: 500px;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  outline: transparent;
}

.customListOption {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;


  outline: transparent;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  padding: 0px 8px;
  border-radius: 0px;
  height: 0px;
  color: rgb(32, 31, 30);
  background-color: white;
  align-items: center;
  width: 100%;
  min-height: 36px;
  line-height: 20px;
  overflow-wrap: break-word;
  user-select: none;

  &:hover {
    background-color: #eee;
  }
}

b {
  margin-left: 5px;
}

.customerHeaderWrapper {
  text-align: center;
  margin: auto;
}

.customerHeader {
	font-size: 36px;
	font-weight: 800;
	font-family: Barlow !important;
}