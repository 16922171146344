@import '../../../../../../styles/colors';

.actionsBlock {
    display: flex;
    margin-bottom: 10px;

    .orderDetails {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;

        .orderDetailsField {
            margin-right: 10px;
            width: 150px;
        }

        .iconButton {
            align-self: flex-end;
        }
    }

    .shortageDetails {
        margin-left: 15px;

        .choiceField {
            display: flex;
            flex-direction: row;
            align-items: center;
        
        > span {
            margin-right: 5px;
        }
        }

        .textFields {
            display: flex;
            flex-direction: row;

            .lineFields {
                display: flex;
                flex-direction: column;
                margin-right: 15px;
            }
        }
    }

    .selectSeries {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        > div:first-child {
            margin-right: 15px;
        }
   
        .selectFields {
            display: flex;
            flex-direction: row;

            .selectField {
                margin-right: 10px;
                width: 90px;
            }

            .selectField:last-child {
                margin-right: 0px;
            }
        }
    }

    .buttonsWrapper {
        & > * {
            margin-right: 0px;
        }
    }

}

.inTransitTires {
    display: flex;
    flex-direction: row;

    .availableInTransitTires {
        margin-right: 15px;
        width: 55%;
    }
}

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.headingBlock {
    align-items: stretch;
    display: flex;

    & > div:first-of-type, & > div:last-of-type {
        display: flex;
        align-items: center;
    }
}

.gyBackground {
    background-color: $gy-blue-color;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.centeredColumn {
    text-align: center;
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.highlight {
    color: $gy-dark-blue-color;
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }