.modalBody {
  margin: 20px;
}

.modalTextContainer {
  margin-top: 50px;
}

.modalButtonsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.modalButtonsContainer>* {
  margin-right: 2px;
}

.gridContainer {
  overflow-y: auto;
  height: 400px;
}

.detailsContainer {
  padding: 20px;
}

.textFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textFieldContainer>* {
  margin-top: 10px;
  margin-bottom: 10px;
}

.descriptionTextField {
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonContainer>* {
  margin-top: 15px;
  margin-bottom: 5px;
  min-width: 100px;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.bottomRow>*:not(:last-child) {
  margin-left: 5px;
  margin-right: 5px;
}