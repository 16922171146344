@import '../../../../../styles/colors';

.wrapper{
  padding: 20px;
}

.emptyFleetBox{
  padding: 30px;
  border: 2px solid;
  border-color: $gy-yellow-color;
  background-color: $gy-yellow-color;
  opacity: .5;
  border-radius: .5em;
}

.btnContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.exportBtn{
  margin-top: 27px;
}

.btnCol{
  text-align: end;
}

.marginRow{
  margin-top: 30px;
}

.divVehicleMakes{
  margin-top: 6px;
  padding-left: 10px;
}

.divVehicleModels{
  margin-top: 6px;
  padding-left: 30px;
}

.divTextFields{
  padding-left: 30px;
}

.divButton{
  margin-top: 23px;
  padding-left: 30px;
}

.titleContainer{
  margin-bottom: 20px;
}

.fieldDropdownBox{
  display: flex;
  align-items: flex-end;
}

.filterDropdownBox{
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 10px;
}

.filterInnerDropdownBox{
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}

.autocompleteContainer {
  margin-top: 6px;
}

.dropdownBox{
  width: 100px;
}

.buttonBox{
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.dropdownBox>*:not(:first-child){
  margin-left: 5px;
  margin-right: 5px;
}

.buttonBox>*{
  margin-left: 5px;
  margin-right: 5px;
}

.filterDropdown{
  max-width: 160px;
}

.highlight {
  color: $gy-dark-blue-color;
}