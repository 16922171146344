@import '../../styles/colors';

.modalBody {
	margin: 50px;
}

.modalFooter {
	display: flex;
	justify-content: flex-end;

	& button:not(:last-of-type) {
			margin-right: 8px;
	}
}

.plantInfoRow {
	display:flex;
	margin-bottom: 16px;
	flex-direction: row;

	.plantInfoField {
		flex: 1;
		margin: 0 5px
	}
}

.plantFilterColumn {
	display: flex;
	margin: 0 8px;
	flex-direction: column;
		
	.plantFilterField{
		flex:1;
		margin-bottom: 5px;			
	}
}

.fullWidth {
	width:100%;
}

.highlight {
	color: $gy-dark-blue-color;
}