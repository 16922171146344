.formContainer {
  display: flex;
  flex-direction: row;
}

.formContainer>*:not(:first-child) {
  margin-left: 10px;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.buttonContainer>*:not(:last-child) {
  margin-left: 10px;
  margin-right: 10px;
}

.formButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: end;
}