@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';

* {
  font-family: Barlow Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tbody td {
  padding: 0.5em 0.75em 0.5em 0.75em;
  border-bottom: 1px solid #ccc;
  vertical-align: middle;
}

thead th {
  cursor: pointer;
  padding: 0.5em 0.75em 0.5em 0.75em;
  text-align: left;
  min-width: 50px;
  min-height: 30px;
  background-color: #114375;
  color: white;

  & div {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 2px;
  }
}

.tableHeading {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 8px 0;
}

.formBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tableHeadingFlex {
  display: flex;
  align-items: center;
}

.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.trBasic {
  &:hover {
    background-color: #eee;
  }
}

.trSelected {
  background-color: rgba($gy-blue-color, 0.3);
}

.round {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: end;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: -10px;
  top: 0;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;

  &>*:not(:first-of-type) {
    margin-left: 8px;
  }
}

.tablesSeparator {
  display: flex;
  flex-direction: column;
  align-items: center;

  &>div {
    height: calc(100% - 80px);
  }
}

.mainRow {
  display: flex;
  align-items: stretch;
}

.filterButton {
  border-radius: 16px;
}

.commentsInfo {
  padding-top: 8px;
}

.tabFiltersBlock {
  display: flex;
  align-items: flex-end;

  &>* {
    margin-right: 16px;
  }
}

.searchInput span {
  margin-left: 10px;
  margin-right: 10px;
}

.emptyRowsTable {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marginLeftRight {
  margin-left: 10px;
  margin-right: 10px;
}

.actionBtns {
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    margin-left: 10px;
  }
}

.editableField,
.editableField div,
.editableField input {
  border: 1px solid transparent
}

.requiredField input {
  border: 1px dotted red;
}

.customListContainer {
  position: relative;
}

.customListOptionsContainer {
  position: absolute;
  z-index: 10000000;
  width: 200px;
}

.customListOption {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;

  outline: transparent;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid transparent;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  padding: 0px 8px;
  border-radius: 0px;
  height: 0px;
  color: rgb(32, 31, 30);
  background-color: white;
  align-items: center;
  width: 100%;
  min-height: 36px;
  line-height: 20px;
  overflow-wrap: break-word;
  user-select: none;
}

.actionsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tableResized {
  border-collapse: collapse;
}

.tableResized,
.tableResized th,
.tableResized td {
  border: 1px solid #ccc;
}

.tableResized th,
.tableResized td {
  padding: 0.5rem;
}

.tableResized th {
  position: relative;
}

.table-responsive {
  padding-right: 10px;
}

.table-title {
  font-size: 22px;
  font-weight: 400;
  padding-bottom: 5px;
}

.table-info {
  margin-top: 12px;
}

.table-wrapper {
  max-height: 400px;
  padding-right: 10px;
  overflow: auto;
}

.table-wrapper::-webkit-scrollbar {
  background-color: rgba(17, 67, 117, 0.15);
  padding-right: 10px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: $gy-dark-blue-color;
  border-radius: 20px;
}

.table-wrapper::-webkit-scrollbar-corner {
  display: none !important;
}

.thead {
  background-color: $gy-dark-blue-color !important;
  color: white;
}

.thead:hover {
  background-color: #4288ce !important;
}

tr:nth-child(even) {
  background-color: rgba(17, 67, 117, 0.15);
}

tr:nth-child(odd) {
  background-color: rgba(17, 67, 117, 0.05);
}

.highlight {
  color: $gy-dark-blue-color;
}

table {
  //border-collapse: collapse;
  width: 100%;
}

table th {
  z-index: 20;
}

table thead tr {
  position: relative;
}

table thead th {
  position: sticky;
  top: 0;
  overflow: auto;
  // min-width: 70px;
}

.resizable {
  resize: horizontal;
  min-width: 50px;
  min-height: 30px;
  overflow: auto;
}

.actionsColumn {
  width: 1%;
}