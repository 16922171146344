@import '../../styles/colors.scss';

.mainWrapper {
    padding: 0 15px 15px 15px;
}

.headerBlock {
    display: flex;
    align-items: center;
    padding: 32px 0px;

    & * {
        color: $gy-blue-color;
    }

    &>.mainIcon {
        font-size: 64px;
        padding-right: 32px;
    }
}

.mainInfoBlock {
    display: flex;
    justify-content: space-between;

    &>div:first-of-type {
        display: flex;
        align-items: center;

        & * {
            color: $gy-blue-color;
        }
    }

    & .mainIcon {
        font-size: 48px;
        padding-right: 24px;
    }
}

thead th {
    cursor: pointer;
    padding: 0.5em 0.75em 0.5em 0.75em;
    text-align: left;
    width: 10%;
    background-color: $gy-blue-color;
    color: white;

    &:hover {
        background-color: rgba($gy-blue-color, 0.5);
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.tableHeading {
    display: flex;
    align-items: flex-end;
    padding: 16px 0 8px 0;

    &>div:first-of-type {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &>div:first-of-type * {
            color: $gy-blue-color;
        }
    }

    &>div:last-of-type {
        display: flex;
        justify-content: flex-end;
    }

    .mainIcon {
        font-size: 24px;
        padding-right: 12px;
    }
}

.pageFooter {
    display: flex;
    justify-content: flex-end;
}

.ellipsButton {
    border-radius: 16px;
    margin-left: 12px;
}

.statusText {
    border-radius: 16px;
    padding: 8px 12px;
    font-weight: bold;
    text-transform: capitalize; 

    & > img {
        margin-left: 12px;
    }
}

.doneStatus {
    @extend .statusText;
    background-color: #BECDED;
    color: #0041A0;
}

.runningStatus {
    @extend .statusText;
    background-color: #DBFFD2;
    color: #2A7200;
}

.errorStatus {
    @extend .statusText;
    background-color: #dc143c;
    color: #F43B3B;
}

.abortedStatus {
    @extend .statusText;
    background-color: #C6C6C6;
    color: #444444;
}

.queuedStatus {
    @extend .statusText;
    background-color: #FFF6D2;
    color: #7A6D34;
}
