@import '../../../../styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    &>div {
        display: flex;
        align-items: center;
    }

    &>div:first-of-type * {
        color: $gy-blue-color;
    }

    .tableHeaderIcon {
        font-size: 24px;
        padding-right: 12px;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.mainRow {
    display: flex;
    align-items: stretch;
}

.tabFiltersBlock {
    display: flex;
    align-items: flex-end;

    &>* {
        margin-right: 16px;
    }
}

.modalContainer {
    min-width: 1200px;
    padding: 12px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;

    &>button {
        position: absolute;
        right: 12px;
        top: 12px
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    &>*:not(:first-of-type) {
        margin-left: 8px;
    }
}

.severityText {
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    padding: 4px 8px;
}

.criticalSeverity {
    @extend .severityText;
    border-color: #dc143c;
    color: #dc143c;
}

.majorSeverity {
    @extend .severityText;
    border-color: #ff7f50;
    color: #ff7f50;
}

.moderateSeverity {
    @extend .severityText;
    border-color: #f0e68c;
    color: #f0e68c;
}

.lowSeverity {
    @extend .severityText;
    border-color: #3cb371;
    color: #3cb371;
}
