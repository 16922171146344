@import '../../../../../styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.modalWrapper {
    min-width: 1200px;
}

.modalContainer {
    padding: 12px;
}

.modalHeader {
    display: flex;
    justify-content: center;

    & > button {
        position: absolute;
        right: 12px;
        top: 12px
    }
}

.modalBody {
    display: flex;

    & > div {
        flex: 1;

        & > div {
            padding-bottom: 15px;
        }
    }
}

.modalFotter {
    display: flex;
    justify-content: flex-end;
}

.postTypeOptions > div {
    display: flex;

    & > div {
        align-self: center;

        & > div > div {
            display: flex;
    
            & > div {
                margin: 0;
                padding-right: 10px;
            }
        }
    }

    & > button {
        align-self: flex-end;
    }
}

.totalInfo > div {
    display: flex;

    & > div {
        padding-right: 10px;
    }

    & > button {
        align-self: flex-end;
    }
}

.billingPeriod > div {
    display: flex;

    & > div {
        padding-right: 10px;
    }
}

.location > div > div {
    display: flex;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    & > div {
        display: flex;
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table thead > tr > th {
    cursor: pointer;
    padding: 0.5em;
    text-align: left;
    width: 10%;
    background-color: $gy-blue-color;
    color: white;

    &:hover {
        background-color: rgba($gy-blue-color, 0.5);
    }

    &:first-of-type {
        width: max-content;
    }

    &:last-of-type {
        padding-right: 1em;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.table tbody > tr > td {
    padding: 0.5em;
    border-bottom: 1px solid #ccc;
    vertical-align: middle;

    &:first-of-type {
        width: max-content;
    }

    &:last-of-type {
        padding-right: 1em;
    }
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.trError {
    background-color: rgba(red, 0.1);
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-of-type) {
        margin-right: 16px;
    }

    & > div {
        margin-left: 16px;
        
        & > button:not(:last-of-type) {
            margin-right: 16px;
        }
    }
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
      color: $gy-dark-blue-color;
  }