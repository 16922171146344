.infoBlock {
    border: 1px solid #004EA8;
    padding: 16px;
    margin-top: 16px;

        & > div {
            display: flex;
            justify-content: space-between;

            & > div {
                & > span {
                    display: block;
                }

                & > span:last-child {
                    font-weight: 600;
                }
            }
        }
}