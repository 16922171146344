@import '../../../../../../styles/colors';

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.headingBlock {
    align-items: stretch;
    display: flex;

    & > div:first-of-type, & > div:last-of-type {
        display: flex;
        align-items: center;
    }
}

.gyBackground {
    background-color: $gy-blue-color;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-of-type) {
        margin-right: 16px;
    }

    & > div {
        margin-left: 16px;
        
        & > button:not(:last-of-type) {
            margin-right: 16px;
        }
    }
}

.table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        cursor: pointer;
        padding: 0.5em 0.75em 0.5em 0.75em;
        text-align: left;
        width: 10%;
        background-color: $gy-dark-blue-color;
        color: white;
    
        &:hover {
            background-color: rgba($gy-dark-blue-color, 0.5);
        }
    }

    tbody td {
        padding: 0.5em 0.75em 0.5em 0.75em;
        border-bottom: 1px solid #ccc;
        vertical-align: middle;
    }

    .viewColumn, .column3, .column5, .column6 {
        width: 2% !important;
    }

    .column4, .column7 {
        width: 20% !important;
    }

    .selectColumn {
        width: 2% !important;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
    color: $gy-dark-blue-color;
}