@import '../../../../../../../../styles/colors';

.headingBlock {
    padding-top: 16px;

    & > span {
        font-weight: 600;
    }

    & > div {
        display: flex;
        justify-content: space-between;

        & > div {
            display: flex;
            flex-direction: column;

            & > span:last-child {
                font-weight: 600;
            }
        }
    }
}

.container {
    & > div {
        display: flex;
        padding-top: 16px;

        & > div {
            display: flex;
            align-items: flex-end;
            min-width: 32px;
            max-width: 175px;
        }

        & > div:not(:last-of-type) {
            margin-right: 18px;
        }
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.highlight {
    color: $gy-dark-blue-color;
}