@import "../../../../../../../../styles/colors";

.detailsWrapper {
    display: block;
  }
  
  .detailsBackground {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 99;
    display: flex;
  }
  
  .detailsContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    max-height: 95%;
    margin: auto;
    align-self: center;
  }
  
  .detailsHeader {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    background-color: $gy-blue-color;
  }
  
.detailsHeader span {
    color: #ffffff;
}

.detailsBody {
    overflow: auto;
    background-color: #ffffff;
    padding: 5px 25px 5px 25px;
}

.detailsWarning {
    text-align: right;
    padding: 10px 25px;
    background-color: #fff;
    color: red;
}

.detailsFooter {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    padding: 10px 25px;
    background-color: #f3f2f1;
}

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        cursor: pointer;
        padding: 0.5em 1.5em 0.5em 0.75em;
        text-align: left;
        width: 10%;
        background-color: $gy-dark-blue-color;
        color: white;
    
        &:hover {
            background-color: rgba($gy-dark-blue-color, 0.5);
        }
    }

    tbody td {
        padding: 0.5em 1.5em 0.5em 0.75em;
        border-bottom: 1px solid #ccc;
        vertical-align: middle;
    }
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.detailsFooter > button {
    margin-left: 10px;
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
      color: $gy-dark-blue-color;
  }