@import '../../../../../../styles/colors';

.marginTop10{
	margin-top: 20px;
}

.highlight {
	color: $gy-dark-blue-color;
}

.searchLocationIcon {
    margin-left: 5px;
}

.statusWrapper {
	display: flex;
	justify-content: flex-end;
	color: $gy-dark-blue-color;
  }