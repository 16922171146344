@import '../../../../../styles/colors';

.modalContainer {
    min-width: 1080px;

    & > div:nth-child(2) {
        overflow: visible;
    }

    & > div > div {
        display: flex;
        padding: 0px 16px 8px 16px;
    }

    & > div > div:first-of-type {
        justify-content: space-between;
        padding-top: 16px !important;
    }

    & > div > div:nth-of-type(2), & > div > div:nth-of-type(5) {
        justify-content: flex-end;
    }

    & > div > div:nth-of-type(4) {
        justify-content: center;
    }

    & > div > div:nth-of-type(5) {
        padding-bottom: 16px !important;

        & > button:last-of-type {
            margin-left: 8px;
        }
    }
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.emptyRowsTable{
	display: flex;
	align-items: center;
	justify-content: center;
}

.searchString {
    margin-right: auto;
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }