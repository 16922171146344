@import '../../../../../styles/colors';
.content {
    padding: 15px 15px 0 15px;

    .details {
        display: flex;
        flex-direction: column;
        margin: 25px;
    
        .detailsWrapper {
            display: flex;
    
            .detailsFields {
                display: flex;
            }

            .detailsField {
                margin-right: 15px;
            }

            .cancelButton {
                align-self: end;
                margin-left: -12px;
            }
        
            .checkboxField {
                margin-top: 10px;
            }

            .checkboxList {
                margin-bottom: 10px;
            }
        }
        
        .actionButton {
            align-self: end;
            margin-top: 15px;
        }
    }

}

.highlight {
    color: $gy-dark-blue-color;
}