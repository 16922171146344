@import '../../../../../../styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.viewButton {
    height: 20px;
}

.refreshButton {
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-top: 20px;
}

.filtersBlock {
    display: flex;
    align-items: baseline !important;
}

.filtersBlock > div:first-child, .filtersBlock > div:nth-of-type(3) {
    padding-right: 16px;
}

.tiresAddingBlock {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    border: 1px solid $gy-blue-color;
    padding: 16px;
}

.gyBackground {
    background-color: $gy-blue-color;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > button:not(:first-child) {
        margin-left: 16px;
    }
}

.useDefaults {
    align-items: flex-start !important;
    flex-direction: column;
    
    & > * {
        margin-right: 8px;
    }
    
    & > div:last-child {
        display: flex;
        border: 2px solid $gy-blue-color;
        padding: 0 0 4px 8px;
        margin-top: 4px;

        & > * {
            margin-right: 8px;
        }
    }
}

.table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        cursor: pointer;
        padding: 0.5em 0.75em 0.5em 0.75em;
        text-align: left;
        width: 10%;
        background-color: $gy-dark-blue-color;
        color: white;
    
        &:hover {
            background-color: rgba($gy-dark-blue-color, 0.5);
        }
    }

    tbody td {
        padding: 0.5em 0.75em 0.5em 0.75em;
        border-bottom: 1px solid #ccc;
    }

    .column4, .column9 {
        width: 5% !important;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.trError {
    background-color: rgba(red, 0.1);
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.makeModel {
    display: block;
    padding-left: 10px;
}

.table-wrapper {
    padding-right: 10px;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
      color: $gy-dark-blue-color;
  }