@import '../../../../../styles/colors.scss';

.modalBody {
  margin: 20px;
}

.modalContainer {
  max-width: 500px;
}


.closed {
  display: none;
}

.separator {
  display: block;
  height: 128px;
  margin: 0 auto;
}

.separator:after {
  background-color: $gy-blue-color;
}

.button {
  display: block;
  width: 150px;
  margin: 4px 0;
}

.mainBlock {
  display: flex;
  align-items: stretch;
}

.centeredContentColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.marginTop20 {
  margin-top: 20px;
}