@import './styles/fonts.scss';
@import './styles/colors.scss';

* {
  font-family: Barlow Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
span,
label,
button,
input {
  font-family: Barlow Regular !important;
}

body {
  margin: 0;
}

.msNavBar {
  background-color: $gy-blue-color;
  height: 50px;
  padding: 0 !important;
}

#root {
  height: 100%;
  padding: 0 !important;
}

html,
body {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

input {
  border: 1px $gy-dark-blue-color solid;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
}

label {
  font-size: 12px !important;
  color: $gy-dark-blue-color !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 0.2pt;
}

.ms-FocusZone.css-118.ms-Pivot.ms-Pivot--tabs.root-109 {
  overflow-y: hidden;
  overflow-x: scroll;
}

.ms-FocusZone.css-118.ms-Pivot.ms-Pivot--tabs.root-109::-webkit-scrollbar {
  background-color: #e5edf6;
  /* border-radius: 20px; */
  height: 15px;
  width: 15px;
}

.ms-FocusZone.css-118.ms-Pivot.ms-Pivot--tabs.root-109::-webkit-scrollbar-thumb {
  background-color: rgba(17, 67, 117, 0.25);
  height: 15px;
  width: 15px;
}

.ms-Pivot-link {
  font-size: 11pt;
  font-weight: 400;
  color: $gy-dark-blue-color;
  background-color: #e5edf6 !important;
  margin-right: 10px;
}

.ms-Pivot-link:hover {
  background-color: #11437548!important;
  transition: 0.2s;
  color: $gy-dark-blue-color;
}

.ms-Pivot-link.is-selected {
  background-color: $gy-dark-blue-color !important;
  color: #FEDA00;
  font-weight: 600;
  transition: 0.2s;
}

.ms-TextField-fieldGroup:hover {
  color: #FEDA00;
}

table {
  color: black;
}
