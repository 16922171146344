/* SIDEBAR STYLES */

.sideBarContainer {
  position: 'fixed';
  width: '130px';
  height: '100%';
  z-index: 5;
  background-color: '#fff';
  padding-top: '5px';
  padding-left: '0px';
  padding-right: '0px';
  padding-bottom: '0px';
  margin: '0px';
  border-right: '5px solid #0C4B8C';
}

.main-body-wrapper {
  float: right;
  margin-top: 30px;
  padding-right: 0px;
}

.sidebarTabActive,
.sidebarTab {
  margin-top: 10px;
  width: 100%;
  height: 48px;

  transition: 0.25s;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebarTabActive {
  background-color: #e5edf6;
  opacity: 1;
}

.sidebarTab {
  background-color: transparent;
  opacity: 0.75;
}

.sidebarTabsLeft {
  background-color: #114375;
  height: 48px;
  width: 10px;
  float: left;
  margin-right: 10px;
  opacity: 0;

  transition: 0.5s;
}

.sidebarTab:hover {
  background-color: #e5edf6;
  opacity: 1;
  cursor: pointer;
}

.sidebarTab:hover .sidebarTabsLeft {
  opacity: 1;
}

.sidebarTabActive .sidebarTabsLeft {
  opacity: 1;
}

.sidebarTab:active {
  background-color: #e5edf6;
}

.sidebarTabs:active .sidebarTabsLeft {
  opacity: 1;
}

.sidebarTabIconWrapper {
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}

.sidebarTabIconWrapper img {
  width: 90%;
}

.sidebarTabsText {
  color: #114375;
  font-size: 16px;
  transition: 0.1s;
  position: relative;
  align-items: center;
  padding: 0;
  white-space: nowrap;


}

.sidebarTabActive .sidebarTabsText,
.sidebarTab:hover .sidebarTabsText {
  font-weight: 600;
}

.sidebarTab .sidebarTabsText {
  font-weight: 400;
}

.userDetails {
  margin-top: 10px;
  transition: 0.5s;
}

a {
  text-decoration: none;
}

.menuToggle {
  position: fixed;
  top: 45px;
  z-index: 60;
  background-color: #e5edf6;
  padding: 5px;
  border-radius: 0px 40px 40px 40px;
  transition: 0.5s;
  box-shadow: 'rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px',
}

.customIcon {
  color: #114375;
}

.customIcon:hover {
  background-color: #e5edf6;
  border: none;
}

.menuOpened {
  left: 14%;
}

.menuClosed {
  left: 3%;
}

/* USER PROFILE STYLES */
.userProfileWrapper {
  background-color: #e5edf6;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: 0.5s;
}

.profilePicture {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePicture img {
  border-radius: 50%;
}

.profilePicture_opened {
  width: 30%;
}

.profilePicture_closed {
  width: 50%;
}

.linkWrapper {
    position: relative;

    .leftNavLink {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        width: inherit;
        height: inherit;
        z-index: 999;
    }
}

/* USER PROFILE STYLES END */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sidebarMenuWrapper {
    padding: 0px;
    transition: 0.5s;
  }

  .headshot {
    width: 50px !important;
    height: 50px;
    margin: auto;
    border-radius: 50px;
  }

  .userName {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    transition: 0.5s;
  }

  .userTitle {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    transition: 0.5s;
  }

  .sidebarTabsWrapper {
    width: 100%;
    height: 100%;
    transition: 0.5s;
  }

  .hide-small {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sidebarMenuWrapper {
    padding: 0px;
    transition: 0.5s;
  }

  .headshot {
    width: 50px !important;
    height: 50px;
    margin: auto;
    border-radius: 50px;
  }

  .userName {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    transition: 0.5s;
  }

  .userTitle {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    transition: 0.5s;
  }

  .sidebarTabsWrapper {
    width: 100%;
    height: 100%;
  }

  .hide-small {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sidebarMenuWrapper {
    height: 640px;
    transition: 0.5s;
  }

  .headshot {
    width: 50px !important;
    height: 50px;
    margin: auto;
    border-radius: 50px;
  }

  .userName {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    transition: 0.5s;
  }

  .userTitle {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    transition: 0.5s;
  }

  .sidebarTabsWrapper {
    width: 100%;
    height: 100%;
    transition: 0.5s;
  }

  .hide-small {
    display: block;
  }
}