.modalContainer {
  min-width: 25%;
  max-width: 80%;
}

.modalBody {
  margin: 20px;
}

.subTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.modalFooter {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}