@import '../../../../../styles/colors';

.details > div {
    padding-bottom: 10px;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.highlight {
    color: $gy-dark-blue-color;
}