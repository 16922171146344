.modalContainer {
  min-width: 90%;
  height: 80% !important;
  width: 80%;
}

.ms-Dialog-main {
  overflow-y: none;
}

.content::-webkit-scrollbar {
  /* solo oculta su visualizacion */
  display: none;
}

.modalBody {
  margin: 50px;
}

.modalButtonsDiv {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  & button:not(first-of-type) {
    margin-right: 8px;
  }
}

.modalFooter {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop25 {
  margin-top: 25px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.registryButtons button {
  margin-top: 36px;
  margin-left: 20px;
}

.marginTop50 {
  margin-top: 50px;
}

.marginLeft20 {
  margin-left: 20px;
}

.amountContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.editBtnContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.editBtnContainer>*:not(:last-child) {
  margin-right: 10px;
}

input:disabled,
textarea:disabled {
  color: black;
}

.spinner {
  font-size: 50px;
  margin-top: 20%;
}

.checkBoxContainer div {
  padding: 10px;
}

.customerSwitch {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.applyContainer {
  margin-top: 6%;
  display: flex;
  justify-content: flex-end;
}

.consignedTiresBtnContainer {
  margin-top: 18px;

}

.consignedTiresBtnContainer button {
  font-size: 10px;
}