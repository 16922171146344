@import '../../../../../../../styles/colors.scss';

.detailsWrapper {
    display: block;
}
  
.detailsBackground {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 99;
    display: flex;
}
  
.detailsContainer {
    width: 90%;
    margin: 45px auto;
    align-self: center;
}

.detailsHeader {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    background-color: $gy-blue-color;
}
  
.detailsHeader span {
    color: #ffffff;
}

.detailsBody {
    background-color: #ffffff;
    padding: 10px 25px 25px 25px;
}

.detailsFooter {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    padding: 10px 25px;
    background-color: #f3f2f1;
}

.detailsFooter > button {
    margin-left: 10px;
  }

.infoBlock {
    display: flex;
    justify-content: space-between;
    border: 1px solid #004EA8;
    padding: 16px;

        & > div {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            padding: 16px 0;

            & > div {
                & > span {
                    display: block;
                    text-align: center;
                }

                & > span:last-child {
                    font-weight: 600;
                }
            }
        }
}

.tableHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    & > div {
        display: flex;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.collapsedBlock {
    display: none;
  }

.scroll {
    overflow-y: auto;
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
    color: $gy-dark-blue-color;
}

.refreshButton {
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
}