@import '../../styles/colors';

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;

  &>* {
    margin-right: 16px;
  }
}

.detailsField {
  margin-bottom: 10px;
}

.highlight {
  color: $gy-dark-blue-color;
}

.processRunning {
  margin-top: 20px;
  font-weight: 'bolder';
  font-size: 20px;

  div {
    margin-bottom: 10px;
  }
}

.backoutContainer {
  border: 1px solid;
  margin-top: 20px;
  padding: 10px;
}