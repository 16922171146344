@import '../../../../../../styles/colors.scss';

.modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-left: 16px;
    }
}

.modalContainer {
    min-width: 720px;
    padding: 12px;
}

.modalBody {
    justify-content: center;
    display: flex;

    & > * {
        width: 320px;

        & > * {
            margin: 16px 0;
        }
    }
}

.copyingInstruction {
    display: flex;
}

.instructionIcon {
    color: red;
    font-size: 24px;
    margin-right: 4px;
}

.mainIcon {
    font-size: 32px;
    padding-right: 16px;
    color: $gy-blue-color;
}
