.modalBody {
	margin: 50px;
}

.modalButtonsDiv {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  & button:not(first-of-type){
    margin-right: 8px;
  }
}

.modalFooter {
  margin-top: 20px;
	display: flex;
	justify-content: flex-end;

	& button:not(:last-of-type) {
			margin-right: 8px;
	}
}

.formContainer {
    display: flex;
    flex-direction: row;
    align-items: end;
}

.formContainer>*:not(:first-child) {
    margin-left: 10px;
}

.buttonContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
  
.buttonContainer>*:not(:last-child) {
    margin-left: 10px;
    margin-right: 10px;
}

.formContainer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.formContainer>*:not(:first-child) {
  margin-left: 5px;
  margin-right: 5px;
}