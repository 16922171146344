@import '../../../../styles/colors';

.availableTermsContainer {
  padding: 0px 35px;

  .topActionButtons {
    margin-bottom: 20px;
  }
}

.titleContainer {
  margin-top: 15px;
  text-align: center;
}

.marginRow {
  margin-top: 24px;
  margin-bottom: 4px;
}

.buttonsGroup {
  justify-content: flex-end;
  display: flex;

  &>*:not(:first-of-type) {
    margin-left: 12px;
  }
}

.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.tableHeading {
  display: flex;
  justify-content: space-between;


  &>* {
    display: flex;
    align-items: center;
  }
}

.trBasic {
  &:hover {
    background-color: #eee;
  }
}

.trSelected {
  background-color: rgba($gy-blue-color, 0.3);
}

.round {
  position: relative;
  float: right;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.customerHeader {
  font-size: 36px;
  font-weight: 800;
  font-family: Barlow !important;
}

.table-wrapper {
  max-height: 400px;
  padding-right: 10px;
  overflow: scroll;
}

.table-wrapper::-webkit-scrollbar {
  background-color: rgba(17, 67, 117, 0.15);
  padding-right: 10px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: $gy-dark-blue-color;
  border-radius: 20px;
}

.table-wrapper::-webkit-scrollbar-corner {
  display: none !important;
}