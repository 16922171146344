.modalContainer {
  min-width: 500px;
}

.modalHeader {
  display: flex;
  justify-content: center;

  & > button {
      position: absolute;
      right: 12px;
      top: 12px
  }
}

.modalBody {
  padding: 12px 12px 200px 12px;

  & > div {
    padding: 10px 0;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
}

.massUpload {
    display: flex;
    justify-content: space-between;
  
    & > *:not(:last-child) {
      margin-right: 25px;
    }
}

.customButton {
  outline: transparent;
  position: relative;
  font-family: Barlow Regular;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid rgb(0, 120, 212);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
  user-select: none;

  & > span {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    & > span {
      display: block;
      flex-grow: 1;
      margin: 0px 4px;
      line-height: 100%;
      font-weight: 600;
    }
  }
}

.customButton.disabled {
  border: 1px solid rgb(243, 242, 241);
  background-color: rgb(243, 242, 241);
  color: rgb(210, 208, 206);
}

.upload {
  display: none;
}