@import '../../../../styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    & > div {
        display: flex;
        align-items: center;
    }
    
    & > div:first-of-type * {
        color: $gy-blue-color;
    }

    .tableHeaderIcon {
        font-size: 24px;
        padding-right: 12px;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    & > *:not(:first-of-type) {
        margin-left: 8px;
    }
}

.mainRow {
    display: flex;
    align-items: stretch;
}

.tableWrapper {
    max-height: 320px;
    overflow-y: auto;
}

.modalContainer {
    min-width: 1200px;
    padding: 12px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;

    & > button {
        position: absolute;
        right: 12px;
        top: 12px
    }
}
