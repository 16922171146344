.modalContainer {
    min-width: 1080px;
    display: flex;
        flex-direction: column;

    & > div:nth-child(2) {
        overflow: visible;
    }

    & > div > div {
        padding: 8px 16px;
    }

    & > div > div:first-of-type {
        justify-content: space-between;
        padding-top: 16px !important;
    }

    & > div > div:nth-of-type(2), & > div > div:last-of-type {
        justify-content: flex-end;
    }

    & > div > div:nth-of-type(4) {
        justify-content: center;
    }

    & > div > div:last-of-type {
        padding-bottom: 16px !important;

        & > button:last-of-type {
            margin-left: 8px;
        }
    }
}

.modalFooter {
    display: flex;
}