@import '../../styles/colors.scss';

.btnContainer button {
  margin-right: 10px;
}

.formContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 20px;
}

.formContainer>*:not(:first-child) {
  margin-left: 10px;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.buttonContainer>*:not(:last-child) {
  margin-left: 10px;
  margin-right: 10px;
}

.fieldDropdownBox {
  display: flex;
  align-items: flex-start;
}

.filterDropdownBox {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 10px;
}

.filterInnerDropdownBox {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}


.dropdown {
  min-width: 100px;
}

.dropdownBox {
  width: 100px;
}

.divVehicleMakes {
  margin-top: 6px;
  padding-left: 10px;
}

.divVehicleModels {
  margin-top: 6px;
  padding-left: 30px;
}

.round {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: end;
}


.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: -10px;
  top: 0;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}


.searchInput span {
  margin-left: 10px;
  margin-right: 10px;
}


.tableHeading {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 8px 0;
}

.tableHeadingFlex {
  display: flex;
  align-items: center;
}

.marginLeftRight {
  margin-left: 10px;
  margin-right: 10px;
}


.highlight {
  color: $gy-dark-blue-color;
}

.resizable {
  resize: horizontal;
  min-width: 50px;
  min-height: 30px;
  overflow: auto;
}


.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.tableWrapper {
  max-height: 400px;
  padding-right: 10px;
  overflow: auto;
}

.tableWrapper::-webkit-scrollbar {
  background-color: rgba(17, 67, 117, 0.15);
  padding-right: 10px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background: $gy-dark-blue-color;
  border-radius: 20px;
}

.tableWrapper::-webkit-scrollbar-corner {
  display: none !important;
}

.expandCollapseContainer {
  padding-top: 3px;
}

.expandCollapseCursor {
  cursor: pointer;
}