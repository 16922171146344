@import '../../styles/colors.scss';

.mainWrapper {
    padding: 0 15px;
}

.headerBlock {
    display: flex;
    align-items: center;
    padding: 32px 0px;

    & * {
        color: $gy-blue-color;
    }

    & > .mainIcon {
        font-size: 64px;
        padding-right: 32px;
    }
}
