.notificationsWrapper {
    position: fixed;
    z-index: 10000000;
    top: 48px;
    width: 400px;
    right: 40px;

    & > div {
        padding: 12px;
        margin: 2px 0;
    }
}

.centeredNotificationsWrapper {
    position: fixed;
    z-index: 10000000;
    top: 0;
    width: 400px;
    left: calc(40vw - 25px);
    bottom: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    
    & > div {
        padding: 12px;
        margin: 2px 0;
    }
}