/* GLOBAL STYLES */
body {
  font-family: 'Barlow', sans-serif !important;
  color: #114375 !important;
}

/* GLOBAL STYLES END */

.quoteContainer {
  position: relative;
  background-color: white;
  padding: 50px;
}

.backButtonContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & button {
    margin-bottom: 20px;
    margin-right: 10px;
  }
}

.quotesInfoContainer {
  display: flex;
  margin-left: auto;

  .infoField {
    display: flex;
    align-items: end;
    margin-left: 10px;
  }
}

.tabsContainer {
  background-color: white;
  padding-bottom: 50px;
}

.pivotBodyContainer {
  height: 100%;
  padding: 30px;
}

.marginRow {
  margin-top: 20px;
  margin-bottom: 5px;
}

.buttonsWrapper {
  justify-content: flex-end;
  display: flex;
  margin-right: 5px;
}

.customerHeaderWrapper {
  text-align: center;
  margin: auto;
}

.customerHeader {
  font-size: 36px;
  font-weight: 800;
  font-family: Barlow !important;
}

.highlight {
  color: #114375 !important;
}