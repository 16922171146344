@import '../../../../styles/colors';

.detailsRow {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 100%;

  .fieldsActions {
    float: right;
    margin-top: 10px;
  }

  button:not(:last-child) {
    margin-right: 15px;
  }

  .detailsColumn {
    width: 100%;
  }
}

.availableLineItemsTable {
  width: 50%;
  margin-right: 30px;

  .bottomInfo {
    margin-top: 10px;
    float: right;
  }
}

.fields {
  display: flex;
  align-items: flex-end;

  .field {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 5px;

    .searchButton {
      margin-left: 5px;
    }
  }

  .dateField {
    padding-bottom: 0 !important;
  }

  .mediumField {
    min-width: 200px;
  }

  .largeField {
    min-width: 250px;
  }

  .field:not(:last-child) {
    margin-right: 15px;
  }

}

.detailsSeparator {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}

.highlight {
  color: $gy-dark-blue-color;
}

.aditionalDetailsFieldsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div:not(:last-child) {
    margin-right: 2%;
  }
}

.lineItemsContainer {
  display: flex;
  margin-left: 0px;
  margin-right: 15px;
}