.billingBasis {
  margin: 1.25rem;
}

.selectedContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
}

.datePickers {
  margin-top: 1rem;
  padding: 0;
}

.marginRight {
  margin-right: 2rem;
}

.marginTop {
  margin-top: 2rem;
}

.noPadding {
  padding: 0;
}

.actionBtns {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;

  & button {
    margin-right: 10px;
  }
}