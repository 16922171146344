@import '../../../../../styles/colors';

.container > div {
    padding: 10px 0;
}

.settings {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    float: right;
}

.settings > div {
    display: flex;
}

.filters {
    display: flex;
}

.filters > div:not(:last-child) {
    align-self: flex-end;
    padding-right: 15px;
}

.infoBlocks {
    display: flex;
    align-items: left;
    width: 100%;
}


.infoBlockData {
    border: 3px solid $gy-blue-color;
    padding: 15px;
    margin-right: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.row span:first-child {
    padding-right: 10px;
}

.row span:last-child {
    font-weight: 500;
}

.highlight {
    color: $gy-dark-blue-color;
}