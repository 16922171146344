@import '../../../../../../styles/colors';

.buttonsWrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;

	& > *:not(:first-of-type) {
			margin-left: 8px;
	}
}

.tableContainer{
	overflow:auto;
}

.upperCase input{
	text-transform: uppercase;
}

.highlight {
	color: $gy-dark-blue-color;
}