@import '../../../../../../styles/colors';

.actions {
    display: flex;
    flex-direction: row;

    .addSeries {
        margin-left: 20px;

        .selectSeries {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            align-items: end;
    
            > div:first-child {
                margin-right: 15px;
            }
       
            .selectFields {
                display: flex;
                flex-direction: row;
    
                .selectField {
                    margin-right: 10px;
                    width: 90px;
                }
    
                .selectField:last-child {
                    margin-right: 0px;
                }
            }

            .actionButton {
                margin-left: 20px;
            }
        }
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.highlight {
    color: $gy-dark-blue-color;
}