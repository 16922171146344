@import '../../../../../../../../styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    & > *:not(:first-of-type) {
        margin-left: 8px;
    }
}

.mainRow {
    display: flex;
    align-items: stretch;
}

.tabFiltersBlock {
    display: flex;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: flex-end;

        & > *:not(:last-child) {
            margin-right: 16px;
        }
    }
}

.cancelCell {
    display: flex;
    justify-content: flex-end;
}

.tableWrapper {
    max-height: 320px;
    overflow-y: auto;
}

.dropdown {
    width: 160px;
}
