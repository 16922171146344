.pdfModalContainer {
    height: 95% !important;
}

.modalBody {
	margin: 50px;
}

.pdfViewerBody {
    margin-top: 20px;
    height: calc(100vh - 205px);
}

.modalFooter {
	bottom: 20px;
	position: absolute;
	right: 40px;

	& button:not(:last-of-type) {
			margin-right: 8px;
	}
}