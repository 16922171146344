@import '../../../../styles/colors';

.filters {

    display: flex;
    align-items: flex-start;

    .field {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: 5px;
    
        .iconButton {
          margin-left: 5px;
        }
      }

    .mediumField {
        min-width: 200px;
    }
    
    .largeField {
        min-width: 250px;
    }

    .field:not(:last-child) {
        margin-right: 15px;
    }
}

.clearCalendarBtn {
    margin-bottom: 5px;
}
