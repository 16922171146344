@import '../../../../../../styles/colors';

.modalContainer {
  min-width: 90%;
  height: 80% !important;
  width: 80%;
}

.ms-Dialog-main {
  overflow-y: none;
}

.content::-webkit-scrollbar {
  display: none;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 10px;
}

.marginTop25 {
  margin-top: 24px;
}

.statusWrapper {
  display: flex;
  justify-content: flex-end;
  color: $gy-dark-blue-color;
}

.spinner {
  font-size: 50px;
  margin-top: 20%;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;

  & > * {
      margin-right: 16px;
  }
}