.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  &>*:not(:first-of-type) {
    margin-left: 8px;
  }
}

.intervalButtons {
  display: flex;
  justify-content: end;
  margin-top: 10px;

  &>*:not(:first-of-type) {
    margin-left: 8px;
  }
}