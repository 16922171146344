.spinner {
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.267s ease 0s;
    top: 0;
    left: 0;
    z-index: 999;
}

.spinner > div {
    width: 64px;
    height: 64px;
}
