.modalBody {
	margin: 50px;
}

.modalButtonsDiv {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & button:not(last-of-type){
    margin-left: 8px;
  }
}

.divAutoCompleteInput {
  margin-top: 10px;
}

.modalFooter {
  margin-top: 20px;
	display: flex;
	justify-content: flex-end;

	& button:not(:last-of-type) {
			margin-right: 8px;
	}
}

.formContainer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.formContainer>*:not(:first-child) {
  margin-left: 5px;
  margin-right: 5px;
}