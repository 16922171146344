@import '~office-ui-fabric-core/dist/css/fabric.min.css';
@import '../../styles/colors.scss';

.header {
  position: fixed;
  top: 0px;
  z-index: 50;
  width: 100%;
}

.stackNavStyles {
  position: relative;
  height: 45px;
  background-color: $gy-blue-color;
  padding: 0px;
  margin: 0px;
}

.IUserStyles {
  position: absolute;
  right: 130px;
  top: 0px;
  height: 45px;
  width: auto;
  padding: 10px 5px;
  cursor: pointer;

  &:hover>div>div {
    color: $gy-white-color !important;
  }
}

.IUserStyles>div>div {
  color: $gy-yellow-color !important;
  font-size: 16px;
}

.IUserPopupStyles {
  padding: 0px 5px;

  .ms-Persona-details {
    .ms-Persona-primaryText {
      font-weight: bold;
      font-size: 16px;
    }

    .ms-Persona-secondaryText {
      font-size: 14px;
    }
  }
}

.LogoClass {
  margin-top: 5px;
  margin-left: 20px;
  height: 30px;
  width: 147px;
}

.SettingsWhite,
.HelpWhite {
  position: absolute;
  right: 0px;
  font-size: 18px;
  padding: 13px 15px;
  cursor: pointer;
  color: $gy-white-color;

  &:hover {
    color: $gy-yellow-color;
  }
}

.SettingsWhite {
  right: 50px;
}

.title {
  font-weight: lighter;
  margin-bottom: 12px;
  font-size: 14px;
}

.callout {
  right: 75px !important;
  padding: 10px;
  background-color: $gy-white-color;
}

.calloutAdmin {
  right: 40px !important;
  padding: 10px;
  background-color: $gy-white-color;
}

.commandBarButton {
  padding: 10px 5px;
}

.inlineIcon {
  padding: 0px 8px;
}

.fullWidthButton {
  width: 100%;
}

.rolLabel {
  text-transform: none;
  margin: 5px;
}