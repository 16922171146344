@import '../../../../styles/colors';

.marginTop10 {
	margin-top: 10px;
}

.marginTop20 {
	margin-top: 20px;
}

.searchBtnContainer {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	
	& button {
		margin-top: 35%;
	}

	& > *:not(:first-of-type) {
			margin-left: 8px;
	}
}

.filterBtnContainer {
	display: flex;
	align-items: flex-end;
	
	& button {
		margin-top: 26px;
	}

	& > *:not(:first-of-type) {
			margin-left: 8px;
	}
}

.buttonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & button {
    margin-top: 3%;
  }

  & > *:not(:first-of-type) {
    margin-left: 8px;
  }
}

.searchOptionContainer {
	margin-top: 20px;
	margin-bottom: 20px;
	padding-right: 50px;
}

.customerSearchOption {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
	width: 100%;	
}

.customerSearchOption input {
	margin-left: 20px;
}

.customerSearchOption_title {
	margin-bottom: 30px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.checkBoxContainer {
	margin-top: 30px;
}

.customerDetailsContainer {
	margin-top: 40px;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.highlight {
	color: $gy-dark-blue-color;
}