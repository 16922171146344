@import '../../../../../../../styles/colors';

.marginTop10{
	margin-top: 10px;
}

.errorLabel{
	color: red
}

.errorInput input {
	border: red 1px solid;
}

.addContainerBtn{
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin-top: 25px;
}

.highlight{
	color: $gy-dark-blue-color;
}