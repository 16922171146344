@import '../../../../../../styles/colors';

.root {
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.267s ease 0s;
    top: 0;
    left: 0;
    z-index: 100;
}

.closed {
    display: none;
}

.window {
    padding: 12px 16px !important;
    background-color: white;
}

.separator {
    display: block;
    height: 128px;
    margin: 0 auto;
}

.separator:after {
    background-color: $gy-blue-color;
}

.button {
    display: block;
    width: 150px;
    margin: 4px 0;
}

.mainBlock {
    display: flex;
    align-items: stretch;
}

.centeredContentColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}