@import '../../../../../../../../styles/colors';

.vehicleNumberSections {
    display: flex;
    flex-direction: row;

    .fields {
        display: flex;
        align-items: baseline;
        
        .field {
            margin-right: 20px;
        }
    }
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .actionButton {
        margin-left: 10px;
    }
}

.highlight {
    color: $gy-dark-blue-color;
}