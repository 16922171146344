@import '../../../../../../styles/colors.scss';

.row {
    display: flex;
}

.mainInfoBlock {
    display: flex;
    justify-content: space-between;

    & > div:first-of-type {
        display: flex;

        & * {
            color: $gy-blue-color;
        }
    }

    & .tooltipIconWrapper {
        display: flex;
        justify-content: flex-end;
    }
}

.accessManagementText {
    margin-right: 20px;
}

.modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    min-width: 1200px;
    padding: 12px;
}
