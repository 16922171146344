@import '../../../../../styles/colors';

.container > div {
    padding: 10px 0;
}

.settings {
    float: right;
    padding: 10px 0;
}

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.emptyRowsTable{
	display: flex;
	align-items: center;
	justify-content: center;
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
      color: $gy-dark-blue-color;
  }