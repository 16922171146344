@import '../../../../../../../../../styles/fonts';
@import '../../../../../../../../../styles/colors';

.modalHeader {
    display: flex;
    justify-content: center;

    & > button {
        position: absolute;
        right: 12px;
        top: 12px
    }
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    & > div {
        display: flex;
    }
}

.modalFooter {
    display: flex;
    justify-content: flex-end;

    & button:not(:last-of-type) {
        margin-right: 8px;
    }
}

.modalContainer {
    min-width: 800px;
}

.modalBody {
    padding: 12px;
}

.filtersBlock {
    align-items: flex-end;

    & button:not(:last-of-type) {
        margin-right: 8px;
    }
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: -10px;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.highlight {
    color: $gy-dark-blue-color;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
