.fields {
    display: flex;
    align-items: baseline;

    .field:not(:last-child) {
        margin-right: 15px;
    }
    
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .actionButton {
        margin-left: 10px;
    }
}
