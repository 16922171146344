@import '../../styles/colors.scss';

.modalHeader {
    display: flex;
    justify-content: center;

    & > button {
        position: absolute;
        right: 12px;
        top: 12px
    }
}

.tableHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    & > div {
        display: flex;
    }
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    & > * {
        margin-left: 16px;
    }
}

.modalContainer {
    min-width: 800px;
}

.modalBody {
    padding: 12px;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.trError {
    background-color: rgba(red, 0.1);
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: -10px;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.useDefaults {
    align-items: flex-start !important;
    flex-direction: column;
    
    & > div:nth-of-type(2) {
        display: flex;
        border: 2px solid $gy-blue-color;
        padding: 0 0 4px 8px;
        margin-top: 4px;

        & > * {
            margin-right: 8px;
        }
    }
}