@import '../../../../../styles/colors';

.rateAdjustmentContainer {
  margin: 1.25rem;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexReverse {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 2rem;
}

.marginButtonSmall {
  margin-bottom: 2rem;

  button {
    margin-top: 20px;
  }
}

.topMargin {
  margin-top: 2rem;
}

.actionButtons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}

.topTableContainer {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}







.marginRow {
  display: flex;
  margin: 8px 0;
}

.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.tableHeading {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;


  &>* {
    display: flex;
    align-items: center;
  }
}

.trBasic {
  &:hover {
    background-color: #eee;
  }
}

.trSelected {
  background-color: rgba($gy-blue-color, 0.3);
}

.round {
  position: relative;
  float: right;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.tableWrapper {
  overflow: auto;
  max-height: 480px;

  & th {
    min-width: 96px !important;
  }
}

.tableWrapper::-webkit-scrollbar {
  background-color: rgba(17, 67, 117, 0.15);
  padding-right: 10px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background: $gy-dark-blue-color;
  border-radius: 20px;
}

.tableWrapper::-webkit-scrollbar-corner {
  display: none !important;
}