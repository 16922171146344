@import '../../../../styles/colors.scss';

.mainInfoBlock {
  display: flex;
  justify-content: space-between;

  &>div:first-of-type {
    display: flex;
    align-items: center;

    & * {
      color: $gy-blue-color;
    }
  }

  & .mainIcon {
    font-size: 48px;
    padding-right: 24px;
  }

  & .tooltipIconWrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.tableHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.tableHeading>div {
  display: flex;
  align-items: center;
}

.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.trBasic {
  &:hover {
    background-color: #eee;
  }
}

.mainRow {
  display: flex;
  align-items: stretch;
}

.tabFiltersBlock {
  display: flex;
  align-items: flex-end;

  &>* {
    margin-right: 16px;
  }
}

.expandButtonContainer {
  text-align: end;
}

.personaInline {
  display: inline-flex;
}

.updateBtnContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.profilePicture {
  width: 30%;
}