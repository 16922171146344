@import '../../../../../../../../styles/colors';

.rowMarginTop {
  margin-top: 10px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;  
	margin-top: 70px;
}

.divIcon {
  font-size: larger;
  text-align: center;
}

.selectSeriesContainer {
	margin-top: 25px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.marginTopRebrand {
	margin-top: 38px;
}

.highlight {
  color: $gy-dark-blue-color;
}