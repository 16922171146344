@import '../../../../../../../../styles/colors';

.container {
    & > div {
        display: flex;
        padding-top: 16px;

        & > div {
            display: flex;
            align-items: flex-end;
            min-width: 32px;
            max-width: 175px;
        }

        & > div:not(:last-of-type) {
            margin-right: 18px;
        }
    }
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}