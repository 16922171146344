@import '../../../../../../../styles/colors.scss';

.detailsWrapper {
    display: block;
}
  
.detailsBackground {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 99;
}
  
.detailsContainer {
    width: 90%;
    margin: 45px auto;
}

.detailsHeader {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    background-color: $gy-blue-color;
}
  
.detailsHeader span {
    color: #ffffff;
}

.detailsBody {
    background-color: #ffffff;
    padding: 10px 25px 50px 25px;
}

.highlight {
    color: $gy-dark-blue-color;
}
