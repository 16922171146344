@import '../../../../styles/colors.scss';

.borderedBlock {
    border: 2px solid $gy-blue-color;
}

.flex {
    display: flex;
}

.spaceBetweenContent {
    justify-content: space-between;
}

.centeredAlignItems {
    align-items: center;
}

.detailsBlock {
    padding: 16px;
}

.highlight {
    color: $gy-dark-blue-color;
}

.highlightDetails {
    color: $gy-dark-blue-color;
    font-weight: 600;
}


.detailsOnly {
    float: right;
    justify-content: right;
    width: 100%;
}