@import '../../styles/colors';

/* GLOBAL STYLES */
body {
  font-family: 'Barlow', sans-serif !important;
  color: #114375 !important;
}

/* GLOBAL STYLES END */

.mainContainer {
  padding: 20px !important;
}

.titleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.iconContainer i {
  padding: 5px;
  font-size: 30px;
}

.headerTabsContainer {
  margin-bottom: 50px;
}

.tabsContainer {
  margin-top: 20px;
}

.pivotBodyContainer {
  height: 100%;
  padding: 30px;
}

.highlight {
  color: $gy-dark-blue-color;
}