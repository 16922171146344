@import '../../styles/colors';

.mainContainer{
	padding: 20px !important;
}

.titleContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.iconContainer i {
	padding: 5px;
	font-size: 30px;
}

.headerTabsContainer {
	margin-bottom: 50px;
}

.tabsContainer {
	margin-top: 20px;
}

.highlight {
	color: $gy-dark-blue-color;
}