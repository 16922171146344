@import '../../../../../../styles/colors';

.viewTireDetailsTabContainer {
    width: 100%;
}

.viewTireDetailsTabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.viewTireDetailsTabHeader > div {
  display: flex;
  align-items: center;
}

.viewTireDetailsTabFooter {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
}

.viewTireDetailsTabFooter > button {
  margin-left: 10px;
}

.highlight {
  color: $gy-dark-blue-color;
}