@import '../../../../styles/colors';

.orderDetailsWrapper {
  margin-top: 20px;
};

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.buttonWrapper>*{
  text-align: center;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
}

.printWrapper {
  text-align: end;
}

.buttonWrapper:first-child{
  margin-top: 25px;
  margin-bottom: 0px;
}

.highlight {
  color: $gy-dark-blue-color;
}