@import '../../../../styles/colors';

.detailsRow {
    display: flex;
    flex-direction: row;
    margin-top: 15px;

    .rowWrapper {
        width: 100%;
    }

    .fieldsActions {
        float: right;
        margin-top: 10px;
    }

    button:not(:last-child) {
        margin-right: 15px;
    }

    .detailsColumn {
        width: 100%;
    }
}

.fields {
    display: flex;
    align-items: flex-end;

    .field {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-bottom: 5px;

        .searchButton {
            margin-left: 5px;
        }
    }

    .dateField {
        padding-bottom: 0 !important;
    }

    .mediumField {
        min-width: 200px;
    }

    .largeField {
        min-width: 300px;
    }

    .field:not(:last-child) {
        margin-right: 15px;
    }

}

.detailsSeparator {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
}

.topInfo {
    float: right;
}

.highlight {
    color: $gy-dark-blue-color;
}