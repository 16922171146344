@import '../../../../../styles/colors';

.inputRow {
    padding: 5px 0;

    span {
        font-weight: 600;
    }
}

.buttonsWrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;

	& > *:not(:first-of-type) {
			margin-left: 8px;
	}
}

.highlight {
	color: $gy-dark-blue-color;
}