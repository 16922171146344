@import "../../../../../../../styles/colors.scss";

.filterWrapper {
  display: block;
}

.filterBackground {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 101;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100vh;
  background: #fff;
  z-index: 10;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(0);
  transition: transform 0.4s ease-in;
  overflow-y: auto;
}

.filterHeader {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-color: $gy-blue-color;
}

.filterHeader span {
  color: #ffffff;
}

.filterBody {
  flex-grow: 1;
  padding: 15px 25px;
}

.filterFooter {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  padding: 10px 25px;
  background-color: rgb(243, 242, 241);
}

.filterFooter > button {
  margin-left: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.column {
  flex-grow: 1;
  padding-right: 10px;
}

.column > div {
  padding-top: 10px;
}
