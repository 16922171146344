@import '../../../../../styles/colors.scss';

.modalContainer {
    min-width: 1110px;

    & > div:nth-child(2) {
        overflow: visible;
    }

    & > div > div {
        display: flex;
        padding: 8px 16px;
    }

    & > div > div:first-of-type {
        justify-content: space-between;
        padding-top: 16px !important;
    }

    & > div > div:nth-of-type(2), & > div > div:last-of-type {
        justify-content: flex-end;
    }

    & > div > div:nth-of-type(4) {
        justify-content: center;
    }

    & > div > div:last-of-type {
        padding-bottom: 16px !important;

        & > button:last-of-type {
            margin-left: 8px;
        }
    }
}

.round {
    position: relative;
    float: right;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: $gy-blue-color;
    border-color: white;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.endAlignedFlexBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0;

    & > *:not(:last-child) {
        margin-right: 4px;
    }
}

.addingFormLine {
    display: flex;
    justify-content: space-between;

    & > *:not(:last-child) {
        margin-right: 4px;
    }
}

.tableWrapper {
    height: 320px;
    overflow-y: auto;
}

.highlight {
    color: $gy-dark-blue-color;
}