@import '../../../../../styles/colors';

.forecastData {
    padding: 15px 15px 0 15px;

    .rateGroupInfo {
        display: flex;
        flex-direction: row;

        > span {
            margin-right: 20px;
       }
    }
    .rateGroup {
        display: flex;
        flex-direction: column;

        .tiresTables {
            display: flex;
            flex-direction: row;
            margin-top: 15px;

            .tiresTable {
                margin-right: 15px;

                .totalsInfo {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    float: right;
                    margin-top: 15px;
                    border: 2px solid $gy-blue-color;

                    .totalsRow {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-bottom: 1px solid #ccc;
                    }
                }
            }
        }

        .figures {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            margin-bottom: 25px;
            width: 65%;
        }

    }

    .figuresTable {
        width: 100%;
        height: min-content;
        margin-right: 20px;

        .figuresCell {
            text-align: right;
            border-spacing: 0px;
            border-collapse: separate;
            border: 1px solid #ccc;

        }

        .figuresLabelCell {
            text-align: left;
        }

        .figuresHeadCell {
            cursor: auto;
            background-color: #d0dbe5;
        }

        .centeredCell {
            text-align: center;
        }
    }

    .summaryFigures {
        width: 65%;
        margin-top: 15px;
        margin-bottom: 15px;

        .summaryCell {
            display: flex;
            justify-content: space-between;
        }
    }

    .actionButtons {
        display: flex;
        justify-content: end;
    }
}

.highlight{
    color:$gy-dark-blue-color;
}
