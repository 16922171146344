@import '../../styles/colors';

.submitContainer {	
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin-top: 20px;
}

.boxContainer {
	& > *:not(:first-of-type) {
			margin-top: 80px;
	}
}

.charCounter {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	font-size: 15px;
}

.headerLabel {
	color: $gy-dark-blue-color;
  }