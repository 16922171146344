@import '~office-ui-fabric-core/dist/css/fabric.min.css';
@import '../../styles/colors.scss';

.record-keeping-container {
  padding: 0px 15px;
  margin: 0 !important;

  .collapsed-block {
    display: none;
  }

  .search-form-header {
    background-color: $gy-blue-color;
    color: $gy-white-color;
    padding: 6px 15px;
    font-size: 20px;
  }

  .search-form-body {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    align-items: end;
  }

  .search-form-body>div {
    width: 40%;
  }

  .search-form-body>div:first-child {
    width: 15%;
  }

  .info-container {
    padding-top: 52px;
  }

  @media screen and (max-width: 1309px) {
    .info-container {
      padding-top: 72px;
    }
  }

  .pivot-gy-colors .is-selected,
  .pivot-gy-colors .ms-Pivot-link:hover {
    background-color: $gy-blue-color;
    color: $gy-white-color !important;
  }
}

.record-keeping-body {
  display: flex;
}

.title {
  margin-top: '5px';
  padding-top: '10px';
  font-family: 'Arial, Helvetica, sans-serif';
  color: $gy-dark-blue-color;
  font-weight: 'bold';
}

.separator {
  display: block;
  margin: 0 auto;
  z-index: auto;
  height: 100%;
  background-color: $gy-blue-color;
  width: 2px;
  padding: 0;
  margin: 0 10px;
}

.autocomplete {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  max-height: 185px;
  overflow: auto;
  border: 1px solid rgba($gy-black-color, 0.4);
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items-error {
  position: absolute;
  max-height: 185px;
  overflow: auto;
  border: 1px solid rgba($gy-black-color, 0.4);
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 59px;
  left: 0;
  right: 0;
}

.autocomplete-items-error div {
  padding: 6px 8px;
  cursor: pointer;
  background-color: $gy-white-color;
  border-bottom: 1px solid rgba($gy-black-color, 0.4);
}

.autocomplete-items div:hover,
.autocomplete-items-error div:hover {
  background-color: #e9e9e9;
}

.autocomplete-items div.active,
.autocomplete-items-error div.active {
  background-color: #e9e9e9;
}

.autocomplete-active {
  background-color: $gy-blue-color !important;
  color: $gy-white-color;
}

.tabulatorWrapper {
  margin: 1rem 0;
}

.dialog-container {
  max-width: fit-content;
}

.pivotBodyContainer {
  height: 100%;
  padding: 30px;
}