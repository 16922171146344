@import '../../../../styles/colors';

.buttonTopMargin {
    margin-top: 10px;
}

.fields {
    display: flex;
    align-items: flex-end;
}

.highlight {
    color: $gy-dark-blue-color;
}