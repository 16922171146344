@import '~office-ui-fabric-core/dist/css/fabric.min.css';
@import '../../styles/colors.scss';

.search-form {
  padding: 1rem;

  &__actions {
    display       : flex;
    flex-direction: row;

    &__buttons {
      margin-left: auto;
    }
  }

  &--small-margin-right {
    margin-right: 1rem;
  }
  
  &--small-margin-left {
    margin-right: 1rem;
  }
  
  &--small-margin-top {
    margin-top: 1.1875rem;
  }
}

