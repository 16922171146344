@import '../../../../../../styles/colors.scss';

.modalFooter {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-left: 16px;
    }
}

.modalContainer {
    min-width: 960px;
    padding: 12px;
}

.modalBody {
    justify-content: space-between;
    display: flex;
}

.copyingInstruction {
    display: flex;
}

.instructionIcon {
    font-size: 24px;
    margin-right: 4px;
}

.mainIcon {
    font-size: 32px;
    padding-right: 16px;
    color: $gy-blue-color;
}

.blockWrapper {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 480px;
}

.permissionsWrapper {
    min-height: 240px;
    border: 2px solid $gy-blue-color;
    padding: 8px;
}

.buttonsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & button {
        display: block;
        width: 100%;
        margin: 4px 0;
    }
}

.permissionButton {
    border-radius: 16px;
    margin: 0 8px 8px 0;
}
