.modalBody {
	margin: 50px;

	& .searchParams {
		display: flex;
		justify-content: space-between;
	}
}

.modalFooter {
	display: flex;
	justify-content: flex-end;

	& button:not(:last-of-type) {
			margin-right: 8px;
	}
}

