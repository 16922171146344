@import '../../styles/colors';

.highlight {
  color: $gy-dark-blue-color;
}

.titleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.titleContainer span {
  margin-top: 6px;
}

.iconContainer {
  padding-right: 10px;
}

.iconContainer img {
  width: 50px;
}