.subsidiaryContainer {
	margin-top: 20px;
}

.addSubsidiaryBtn button {
	margin-top: 18px;
	margin-left: 20px;
}

.alertWarning {
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
}