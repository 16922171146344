@import '../../../styles/colors';

.highlight {
    color: $gy-dark-blue-color;
}

.collapseActionContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 15px;
}

.collapseActionContainer > button {
    border: none;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
}