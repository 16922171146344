@import '../../../../../../styles/colors';

.submitCol {
  display: flex;
	justify-content: flex-end;
	margin-top: 8px;

	& > *:not(:first-of-type) {
			margin-left: 8px;
	}
}

.rowTables {
  margin-top: 50px;
}

.marginTop20{
	margin-top: 20px;
}

.highlight {
	color: $gy-dark-blue-color;
}