@import '../../../../../styles/colors';

.colSubmit{
  padding-right: 40px;
  margin-right: 50px;
  text-align: end;
}

.wrapper{
  margin: 20px;
}

.upperRow{
  margin-bottom: 50px;
}

.commonOwnerInput{
  margin-top: 10px;
}

.btnContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.highlight {
  color: $gy-dark-blue-color;
}