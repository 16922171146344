@import '../../../../../styles/colors.scss';

.root {
  margin: 10 !important;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.267s ease 0s;
  top: 0;
  left: 0;
  z-index: 100;
}

.window {
  padding: 40px 40px !important;
  background-color: white;
  height: 100%;
  min-width: 640px;
}

.separator {
  display: block;
  height: 128px;
  margin: 0 auto;
}

.separator:after {
  background-color: $gy-blue-color;
}

.modalHeading {
  display: flex;
  justify-content: space-between;
}

.button {
  display: block;
  width: 150px;
  margin: 4px 0;
}

.mainBlock {
  display: flex;
  align-items: stretch;
}

.centeredContentColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltipIconWrapper {
  display: flex;
  justify-content: flex-end;
}

.errorIcon {
  font-size: 24px;
}

.row {
  display: flex;
  padding: 16px 0;
}

.boldText {
  font-weight: bold;
}

.moduleButton {
  border-radius: 16px;
  margin: 0 8px 8px 0;
}

.accessTypes {
  display: flex;
  max-width: fit-content;
  flex-wrap: wrap;
}