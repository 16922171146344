@import '../../../../../styles/colors';

.row {
  margin-bottom: 16px;
}

.buttonsRow {
  display: flex;
  justify-content: flex-end;
}

.highlight {
  color: $gy-dark-blue-color;
}
