@import '../../styles/colors.scss';

.root {
    display: flex;
    justify-content: center;
}

.root > button:first-child:hover, .root > button:last-child:hover {
    color:rgba(17,67,117,1);
    background:none;
}

.root > button:first-child, .root > button:last-child {
    background:none;
}

.root button:hover, .activePage {
    background-color: $gy-blue-color;
    color: white;
    border-radius:50%;
    border: 6px white solid;
}

.root button {
    padding: 0px;
    min-height: 40px;
}

.emptyPagination {
    visibility: hidden;
}

.pagesSeparator {
    align-self: center;
    padding: 0 8px;
}