.modalContainer {
    min-width: 800px;
    width: 1180px;
    display: flex;
        flex-direction: column;

    & > div:nth-child(2) {
        overflow: visible;
    }

    & > div > div {
        padding: 8px 16px 0px 16px;
    }

    & > div > div:first-of-type {
        justify-content: space-between;
        padding-top: 16px !important;
    }

    & > div > div:nth-of-type(2), & > div > div:last-of-type {
        justify-content: flex-end;
    }

    & > div > div:nth-of-type(4) {
        justify-content: center;
    }

    & > div > div:last-of-type {
        padding-bottom: 16px !important;

        & > button:last-of-type {
            margin-left: 8px;
        }
    }
}

.controlValueDetails {
    display: flex;
    justify-content: flex-start !important;

    .controlValuesList {
        width: 250px;
        margin-right: 20px;
        align-self: center;
    }

    .controlValueField {
        margin-left: 20px;
    }
}

  .formContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start !important;
    padding: 2px 16px !important;

    .largeField {
        width: 400px;
        min-width: 250px;
    }
  }
  
  .formContainer>*:not(:first-child) {
    margin-left: 10px;
  }
  
.modalFooter {
    display: flex;
    justify-content: end !important;

    & > button:last-of-type {
        margin-left: 8px;
    }
}
