@import '../../../../../../styles/colors';

.tableHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.searchField {
    display: flex !important;
    justify-content: unset !important;
    align-items: flex-end;
}

.vehicleInfoBlock {
    display: flex;
    justify-content: space-between;
    border: 1px solid $gy-blue-color;
    & > div {
        flex-grow: 1;
        padding: 16px;

        & > div {
            display: flex;
            justify-content: space-between;

            & > div {
                display: flex;
                flex-direction: column;

                & > span:last-child {
                    font-weight: 600;
                }
            }
        }
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-right: 16px;
    }
}

.emptyRowsTable{
	display: flex;
	align-items: center;
	justify-content: center;
}

.highlight {
    color: $gy-dark-blue-color;
}

.table-wrapper {
    max-height: 600px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }