@import '../../../../../../styles/colors.scss';

.tableHeading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 0;
}

.tableHeading > div {
    display: flex;
    align-items: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        cursor: pointer;
        padding: 0.5em 0.75em 0.5em 0.75em;
        text-align: left;
        width: 10%;
        background-color: $gy-dark-blue-color;
        color: white;
    
        &:hover {
            background-color: rgba($gy-dark-blue-color, 0.5);
        }
    }

    tbody td {
        padding: 0.5em 0.75em 0.5em 0.75em;
        border-bottom: 1px solid #ccc;
        vertical-align: middle;
    }

    .viewColumn, .column4, .column5 {
        width: 2% !important;
    }

    .column3, .column6 {
        width: 20% !important;
    }

    .selectColumn {
        width: 2% !important;
    }
}

.ascending::after {
    content: '\2191';
    display: inline-block;
    margin-left: 1em;
}

.descending::after {
    content: '\2193';
    display: inline-block;
    margin-left: 1em;
}

.table tbody td {
    padding: 0.5em 0.75em 0.5em 0.75em;
    border-bottom: 1px solid #ccc;
}

.trBasic {
    &:hover {
        background-color: #eee;
    }
}

.trSelected {
    background-color: rgba($gy-blue-color, 0.3);
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-of-type) {
        margin-right: 16px;
    }

    & > div {
        margin-left: 16px;
        
        & > button:not(:last-of-type) {
            margin-right: 16px;
        }
    }
}

.table-wrapper {
    max-height: 400px;
    padding-right: 10px;
    overflow: scroll;
  }
	
  .table-wrapper::-webkit-scrollbar {
    background-color: rgba(17, 67, 117, 0.15);
    padding-right: 10px;
    border-radius: 20px;
    height: 15px;
    width: 15px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: $gy-dark-blue-color;
    border-radius: 20px;
  }
  
  .table-wrapper::-webkit-scrollbar-corner {
    display: none !important;
  }

  .highlight {
    color: $gy-dark-blue-color;
}