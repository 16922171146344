@import '../../../../styles/colors.scss';

.borderedBlock {
    border: 2px solid $gy-blue-color;
}

.flex {
    display: flex;
}

.spaceBetweenContent {
    justify-content: space-between;
}

.centeredAlignItems {
    align-items: center;
}


.detailsBlock {
    padding: 16px;
    margin: 8px 0;
}

.paddedElement {
    padding: 8px;
}

.nonFloat {
    float: none !important;
}

.baselineItems label{
    align-items: baseline;
}

.buttonsWrapperBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttonsGroup {
    align-self: flex-end;
}

.buttonsGroup button {
    padding: 16px 32px;
    height: auto;
}

.buttonsGroup button:first-child {
    margin-right: 2px;
}

.buttonsGroup button:last-child {
    margin-left: 2px;
}

.customerInformationWrapper {
    display: flex;
    align-content: stretch;
}

.optionsWrapper {
    display: flex;
}

.highlight {
    color: $gy-dark-blue-color !important;
}
