@import '../../../../../../styles/colors';

.applyButton {
  margin-top: 8px;
}

.tableHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  margin-top: 16px;
}

.tableHeading>div {
  display: flex;
  align-items: center;
}

.gyBackground {
  background-color: $gy-blue-color;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;

  &>* {
    margin-right: 8px;
  }
}

.availablePlantsTable {
  .column1 {
    width: 4% !important;
  }

  .column2,
  .column3,
  .column4 {
    width: 8% !important;
  }

  .column5,
  .column6,
  .column7,
  .column8 {
    width: 1% !important;
  }

  .column9,
  .column11 {
    width: 5% !important;
  }

  .selectColumn {
    width: 0% !important;
  }
}

.ascending::after {
  content: '\2191';
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: '\2193';
  display: inline-block;
  margin-left: 1em;
}

.trBasic {
  &:hover {
    background-color: #eee;
  }
}

.trAdded {
  background-color: #e8f3dc;
}

.trSelected {
  background-color: rgba($gy-blue-color, 0.3);
}

.round {
  position: relative;
  float: right;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: $gy-blue-color;
  border-color: white;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.highlight {
  color: $gy-dark-blue-color;
}

.table-wrapper {
  max-height: 400px;
  padding-right: 10px;
  overflow: scroll;
}

.table-wrapper::-webkit-scrollbar {
  background-color: rgba(17, 67, 117, 0.15);
  padding-right: 10px;
  border-radius: 20px;
  height: 15px;
  width: 15px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: $gy-dark-blue-color;
  border-radius: 20px;
}

.table-wrapper::-webkit-scrollbar-corner {
  display: none !important;
}

.resizable {
  resize: horizontal;
  min-width: 50px;
  min-height: 30px;
  overflow: auto;
}