@import '../../../../../../styles/colors';;

.marginTop10{
	margin-top: 20px;
}

.errorLabel{
	color: red
}

.errorInput input {
	border: red 1px solid;
}

.dotNumLabel label{
	font-size: 18px !important;
	text-transform: uppercase;
	letter-spacing: 6px;
	font-weight: 100 !important;
}

.dotNumLabel input {
	text-transform: uppercase;
	letter-spacing: 6px;
}

.highlight {
	color: $gy-dark-blue-color;
}