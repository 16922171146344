.modalBody {
  margin: 50px;
}

.modalFooter {
  bottom: 20px;
  position: absolute;
  right: 20px;

  & button:not(:last-of-type) {
    margin-right: 8px;
  }
}